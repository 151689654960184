<template>

    <b-container>
        <h1>FAQ</h1>

        <!-- Question 1 -->
        <b-card bg-variant="default" text-variant="black" class="flex-fill mb-4">
            <b-row>
                <b-col class="d-flex flex-column align-items-center">
                    <b-card-text>
                        1. How much money did the 2022 movie <i>Morbius</i> make?
                    </b-card-text>

                    <b-button variant="primary" @click="ToggleFAQAnswer1">Answer</b-button>
                </b-col>
                <b-col class="d-flex align-items-center">
                    <b-card-text id="faqanswer1" v-model="faqAnswer1">
                        The internetz states that <i>Morbius</i> made $163,000,089 which, translated to its true currency, is {{ this.faqAnswer1 }} Morbillion Dollars (MBD).
                    </b-card-text>
                </b-col>
            </b-row>
        </b-card>

        <!-- Question 2 -->
        <b-card bg-variant="default" text-variant="black" class="flex-fill mb-4">
            <b-row>
                <b-col class="d-flex flex-column align-items-center">
                    <b-card-text>
                        2. How much money is the U.S. National Debt in Morbillion Dollars?
                    </b-card-text>

                    <b-button variant="primary" @click="ToggleFAQAnswer2">Answer</b-button>
                </b-col>
                <b-col class="d-flex align-items-center">
                    <b-card-text id="faqanswer2" v-model="faqAnswer2">
                        As of 2022-07-31, the U.S. National Debt was $30,617,348,300,000 or equivalently, {{ this.faqAnswer2 }} Morbillion Dollars (MBD).
                    </b-card-text>
                </b-col>
            </b-row>
        </b-card>

        <!-- Question 3 -->
        <b-card bg-variant="default" text-variant="black" class="flex-fill mb-4">
            <b-row>
                <b-col class="d-flex flex-column align-items-center">
                    <b-card-text>
                        3. What is your GPS location so that I can find you and morb on you?
                    </b-card-text>

                    <b-button variant="primary" @click="ToggleFAQAnswer3">Answer</b-button>
                </b-col>
                <b-col class="d-flex align-items-center">
                    <b-card-text id="faqanswer3">
                        37.2431° N, 115.7930° W
                    </b-card-text>
                </b-col>
            </b-row>
        </b-card>
    </b-container>

</template>

<script>
const axios = require('axios').default;
const inputMorbiusMovieRevenue = {
    currency: "USD",
    amount: 163000089
};
const inputUSNationalDebt = {
    currency: "USD",
    amount: 30617348300000
};

export default {
    name: "faq",
    data() {
        return {
            faqAnswer1: null,
            faqAnswer2: null
        }
    },
    methods: {
        RestAPIFailureToast(error) {
            this.$bvToast.toast(error, {
            title: "Your Money Wasn't Morbed",
            variant: 'danger',
            solid: true,
            appendToast: true,
            toaster: 'b-toaster-bottom-center'
            });
        },
        async ToggleFAQAnswer1() {
            await this.GetFAQAnswer1();
            document.getElementById("faqanswer1").style.visibility = document.getElementById("faqanswer1").style.visibility == "visible" || document.getElementById("faqanswer1").style.visibility === null ? "hidden" : "visible";
        },
        async ToggleFAQAnswer2() {
            await this.GetFAQAnswer2();
            document.getElementById("faqanswer2").style.visibility = document.getElementById("faqanswer2").style.visibility == "visible" || document.getElementById("faqanswer2").style.visibility === null ? "hidden" : "visible";
        },
        ToggleFAQAnswer3() {
            document.getElementById("faqanswer3").style.visibility = document.getElementById("faqanswer3").style.visibility == "visible" || document.getElementById("faqanswer3").style.visibility === null ? "hidden" : "visible";
        },
        async GetFAQAnswer1() {
            if (document.getElementById("faqanswer1").style.visibility === "visible") {
                return;
            }

            try {
                const response = await axios({
                    method: 'post',
                    url: process.env.VUE_APP_Morbillion_Dev_REST_API_Default_Endpoint,
                    data: inputMorbiusMovieRevenue
                })

                console.log(response.data);

                this.faqAnswer1 = response.data.amount;
            }
            catch (error) {
                console.log(error);
                this.RestAPIFailureToast(error.message);
            }
        },
        async GetFAQAnswer2() {
            if (document.getElementById("faqanswer2").style.visibility === "visible") {
                return;
            }

            try {
                const response = await axios({
                    method: 'post',
                    url: process.env.VUE_APP_Morbillion_Dev_REST_API_Default_Endpoint,
                    data: inputUSNationalDebt
                })

                console.log(response.data);

                this.faqAnswer2 = response.data.amount;
            }
            catch (error) {
                console.log(error);
                this.RestAPIFailureToast(error.message);
            }
        }
    }
}
</script>

<style>
#faqanswer1, #faqanswer2, #faqanswer3 {
    visibility: hidden;
}
</style>