<template>
  <b-container>
        <b-row class="mb-4 justify-content-center">
            <b-card bg-variant="warning" text-variant="black" class="flex-fill">
                <b-card-text>
                    Coming soon.<br>&#129299;
                </b-card-text>
            </b-card>
        </b-row>
  </b-container>
</template>

<script>
export default {
    name: "api"
}
</script>

<style>

</style>