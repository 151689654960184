<template>
    <div>
        <b-container>
            <b-row class="mb-4 justify-content-center">
                <b-card bg-variant="warning" text-variant="black" class="flex-fill">
                    <b-card-text>
                        Due to the extreme volatility of the Morbillion Dollars (MBD) currency, the currency conversion ratios change every 0.00574891356 picoseconds. - &#129299;
                    </b-card-text>
                </b-card>
            </b-row>

            <!-- Input Elements -->
            <b-row class="mb-2">
                <b-col class="px-0 d-flex justify-content-start align-items-center">
                    <label>Enter currency: </label>
                </b-col>
                <b-col class="px-0 d-flex justify-content-start align-items-center">
                    <b-form-input type="text" placeholder="USD" v-model="inputCurrency" :state="InputCurrencyState"></b-form-input>
                </b-col>
            </b-row>
            <b-row class="mb-4">
                <b-col class="px-0 d-flex justify-content-start align-items-center">
                    <label>Enter amount: </label>
                </b-col>
                <b-col class="px-0 d-flex justify-content-start align-items-center">
                    <b-form-input type="number" placeholder=0 v-model="inputAmount" :state="InputAmountState"></b-form-input>
                </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center mb-4">
                <b-button @click="ResetInputs()" class="mr-4">Reset</b-button>
                <b-button variant="success" @click="ConvertCurrency()">Morb my money</b-button>
            </b-row>

            <!-- Output Elements -->
            <b-row class="mb-2">
                <b-col class="px-0 d-flex justify-content-start align-items-center">
                    <label>Currency: </label>
                </b-col>
                <b-col class="px-0 d-flex justify-content-start align-items-center">
                    <b-form-input type="text" placeholder="" v-model="outputCurrency" :state="OutputCurrencyState"></b-form-input>
                </b-col>
            </b-row>
            <b-row class="mb-4">
                <b-col class="px-0 d-flex justify-content-start align-items-center">
                    <label>Amount: </label>
                </b-col>
                <b-col class="px-0 d-flex justify-content-start align-items-center">
                    <b-form-input type="number" v-model="outputAmount" :state="OutputAmountState"></b-form-input>
                </b-col>
            </b-row>

            <b-row class="justify-content-center pb-3">
                <b-card bg-variant="dark" text-variant="white" class="text-center" id="responsecard">
                    <b-card-text v-model="outputMessage">{{ outputMessage }}</b-card-text>
                </b-card>
            </b-row>
        </b-container>
    </div>
</template>

<script>
const axios = require('axios').default;

export default {
    name: 'Morb-Page',
    data() {
        return {
            inputCurrency: '',
            inputAmount: null,
            outputCurrency: null,
            outputAmount: null,
            outputMessage: null
        }
    },
    computed: {
        InputAmountState() {
            return this.inputAmount != 0 && this.inputAmount != null;
        },
        InputCurrencyState() {
            return this.inputCurrency != '' && this.inputCurrency != null;
        },
        OutputAmountState() {
            return this.outputAmount != 0 && this.outputAmount != null;
        },
        OutputCurrencyState() {
            return this.outputCurrency != '' && this.outputCurrency != null;
        }
    },
    watch: {
        outputMessage: function() {
            document.querySelector('#responsecard').style.visibility = this.OutputMessageState() ? 'visible' : 'hidden';
        }
    },
    methods: {
      InputsValidatedToast() {
        this.$bvToast.toast("Morbin' your money...", {
          title: "It's Morbin' Time",
          variant: 'success',
          solid: true,
          appendToast: true,
          toaster: 'b-toaster-bottom-center'
        });
      },
      InputsInvalidatedToast() {
        this.$bvToast.toast("Fix your freaking inputs.", {
          title: "Failed Inputs",
          variant: 'danger',
          solid: true,
          appendToast: true,
          toaster: 'b-toaster-bottom-center'
        });
      },
      RestAPIFailureToast(error) {
        this.$bvToast.toast(error, {
          title: "Your Money Wasn't Morbed",
          variant: 'danger',
          solid: true,
          appendToast: true,
          toaster: 'b-toaster-bottom-center'
        });
      },
      async ConvertCurrency() {
        const validated = this.ValidateInputs();
        if (validated === true){
            this.InputsValidatedToast();
        }
        else {
            this.InputsInvalidatedToast();
            return;
        }

        const currency = this.inputCurrency;
        const amount = this.inputAmount;

        const incomingRequest = {
            currency: currency,
            amount: amount
        };

        try {
            const response = await axios({
                method: 'post',
                url: process.env.VUE_APP_Morbillion_Dev_REST_API_Default_Endpoint,
                data: incomingRequest
            })

            console.log(response.data);

            this.outputCurrency = response.data.currency;
            this.outputAmount = response.data.amount;
            this.outputMessage = response.data.message;
        }
        catch (error) {
            console.log(error);
            this.RestAPIFailureToast(error.message);
        }
      },
      OutputMessageState() {
            return this.outputMessage != '' && this.outputMessage != null;
      },
      ValidateInputs() {
        return this.InputCurrencyState && this.InputAmountState;
      },
      ResetInputs() {
        this.inputCurrency = null;
        this.inputAmount = null;
        this.outputCurrency = null;
        this.outputAmount = null;
        this.outputMessage = null;
      }
    }
}
</script>

<style scoped>
#responsecard {
    visibility: hidden;
}
div .container > * {
    padding-left: 30px;
    padding-right: 30px;
}
</style>