import Vue from 'vue';
import VueRouter from 'vue-router';
import Morb from '../views/Morb-Page.vue';
import FAQ from '../views/faq.vue';
import API from '../views/api.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Morb
  },
  {
    path: '/api',
    name: 'api',
    component: API
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

export default router